export const Status = {
  LOADING: "LOADING",
  NORMAL: "NORMAL",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const QuoteStatus = {
  0: "Pending",
  1: "Processing",
  2: "Ready",
  3: "Payment Made",
  4: "Repayment",
  5: "Completed",
  6: "Loan Approved",
  7: "Expired",
  8: "Rejected",
};

export const QuoteRequestStatusEnum = {
  0: "Processing",
  1: "Changed",
  2: "Ready",
  3: "Payment",
  4: "Completed",
};

export const InstallationStatus = {
  0: "Payment Made",
  1: "Engineer Assigned",
  2: "Pending Installation",
  3: "Installation Done",
  4: "Installation Confirmed",
};

export const ComplainsStatus = {
  0: "Submitted",
  1: "Resolved",
  2: "Closed",
};

export const PaymentModeStatus = {
  0: "Card",
  1: "Transfer",
};

export const UserRoleStatus = {
  0: "Customer",
  1: "Support",
  2: "Admin",
  3: "Sponsor",
  5: "Sales Agent",
  6: "Bank User",
  7: "Sales Coordinator",
};

export const GeneratorCapacityStatus = {
  0: "1 Kva",
  1: "2 Kva",
  2: "5 Kva",
  3: "More than 5 Kva",
  4: "Others",
};

export const MaintenanceStatus = {
  0: "Scheduled",
  1: "Active",
  2: "Closed",
};

export const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "FCT",
];
