import { createLogger, createStore } from "vuex";

import admin from "./modules/admin";
import auth from "./modules/auth";
import bank from "./modules/bank";
import createPersistedState from "vuex-persistedstate";
import financial from "./modules/financial";
import inventory from "./modules/inventory";
import maintenance from "./modules/maintenance";
import mandate from "./modules/mandate";
import product_quote from "./modules/product.quote";
import quote from "./modules/quote";

const debug = process.env.NODE_ENV !== "production";

const store = createStore({
  modules: {
    auth,
    admin,
    mandate,
    bank,
    product_quote,
    quote,
    financial,
    inventory,
    maintenance,
  },
  strict: debug,
  plugins: debug ? [createLogger(), createPersistedState()] : [],
});

export default store;
