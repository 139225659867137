import * as ACTIONS from "./../quote.mutation";
// import { reactive, ref } from "vue";
import QuoteApiService from "@/services/quote.axios";
import { Status } from "@/types";

const state = () => ({
  status: Status,
  productQuoteStatus: "",
  productError: "",
});

// actions
const actions = {
  // create product
  createProduct({ commit }, productCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_PRODUCT_LOADING, Status.LOADING);
      QuoteApiService.postRequest("Product/CreateProduct", productCredentials)
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_PRODUCT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_PRODUCT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all products
  allProducts({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_PRODUCT_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Product/GetProducts?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_PRODUCT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_ALL_PRODUCT_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get product by ID with related properties
  productById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_PRODUCT_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Product/GetProductWithRelatedProperties?id=${id}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_PRODUCT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_PRODUCT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Product
  updateProduct({ commit }, productCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_PRODUCT_LOADING, Status.LOADING);
      QuoteApiService.postRequest("Product/UpdateProduct", productCredentials)
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_PRODUCT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_PRODUCT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Create Product Category
  createProductCategory({ commit }, productCatCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_PRODUCT_CATEGORY_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Product/CreateProductCategory",
        productCatCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_PRODUCT_CATEGORY_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_PRODUCT_CATEGORY_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all Product Categories
  allProductCategories({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_PRODUCT_CATEGORY_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Product/GetProductCategories?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_PRODUCT_CATEGORY_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_PRODUCT_CATEGORY_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Product Category by ID
  productCategoryById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_PRODUCT_CATEGORY_LOADING, Status.LOADING);
      QuoteApiService.getRequest(`Product/GetProductCategory?id=${id}`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_PRODUCT_CATEGORY_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_PRODUCT_CATEGORY_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Product Category
  updateProductCategory({ commit }, productCatCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_PRODUCT_CATEGORY_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Product/UpdateProductCategory",
        productCatCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_PRODUCT_CATEGORY_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_PRODUCT_CATEGORY_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Create Product Components
  createProductComponents({ commit }, productComponentCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_PRODUCT_COMPONENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Product/MapProductComponent",
        productComponentCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_PRODUCT_COMPONENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_PRODUCT_COMPONENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Product Component
  updateProductComponent({ commit }, productComponentCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_PRODUCT_COMPONENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Product/UpdateProductComponentQty",
        productComponentCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_PRODUCT_COMPONENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_PRODUCT_COMPONENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Delete Product Component
  deleteProductComponent({ commit }, credential) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.DELETE_PRODUCT_COMPONENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(`Product/UnmapProductComponent`, credential)
        .then(({ data }) => {
          // commit(ACTIONS.DELETE_PRODUCT_COMPONENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.DELETE_PRODUCT_COMPONENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Create Product Supplier
  createProductSupplier({ commit }, productSupCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_PRODUCT_SUPPLIER_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Suppliers/CreateSupplier",
        productSupCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_PRODUCT_SUPPLIER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_PRODUCT_SUPPLIER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all Product Suppliers
  allProductSuppliers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_PRODUCT_SUPPLIERS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Suppliers/GetAllSuppliers?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_PRODUCT_SUPPLIERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_PRODUCT_SUPPLIERS_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Product Supplier by ID
  productSupplierById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_PRODUCT_SUPPLIER_LOADING, Status.LOADING);
      QuoteApiService.getRequest(`Suppliers/GetSupplierById?id=${id}`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_PRODUCT_SUPPLIER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_PRODUCT_SUPPLIER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Product Supplier
  updateProductSupplier({ commit }, productSupCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_PRODUCT_SUPPLIER_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Suppliers/UpdateSupplier",
        productSupCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_PRODUCT_SUPPLIER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_PRODUCT_SUPPLIER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all employers
  allEmployers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_EMPLOYERS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `UserEmployer/GetAllUserEmployers?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&name=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_EMPLOYERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_EMPLOYERS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Create Employer
  createEmployer({ commit }, employerCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_EMPLOYERS_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "UserEmployer/CreateUserEmployer",
        employerCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_EMPLOYERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_EMPLOYERS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Employer
  updateEmployer({ commit }, employerCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_EMPLOYERS_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "UserEmployer/UpdateUserEmployer",
        employerCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_EMPLOYERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_EMPLOYERS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all Installation Components
  allInstallationComponents({ commit }) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        // `Product/GetProductCategories?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
        `Product/GetInstallationComponents`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_SUCCESS, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Create Installation Component
  createInstallationComponent({ commit }, productComponentCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_INSTALLATION_COMPONENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Product/CreateInstallationComponent",
        productComponentCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_INSTALLATION_COMPONENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.CREATE_INSTALLATION_COMPONENT_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // Update Installation Component
  updateInstallationComponent({ commit }, productComponentCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_INSTALLATION_COMPONENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Product/UpdateInstallationComponent",
        productComponentCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_INSTALLATION_COMPONENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.UPDATE_INSTALLATION_COMPONENT_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // Get all Installation Component Categories
  allInstallationComponentCategories({ commit }) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(
        ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_LOADING,
        Status.LOADING
      );
      QuoteApiService.getRequest(`Product/GetInstallationComponentCategory`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_SUCCESS, data);
          resolve(data.categories);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Update Product Pricing
  calculateProductPricing({ commit }) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_PRODUCT_PRICING_LOADING, Status.LOADING);
      QuoteApiService.postRequest("Product/CalculateProductPricing")
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_PRODUCT_PRICING_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_PRODUCT_PRICING_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  [ACTIONS.UPDATE_PRODUCT_PRICING_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_PRODUCT_PRICING_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_PRODUCT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_PRODUCT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_PRODUCT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_PRODUCT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_INSTALLATION_COMPONENT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_INSTALLATION_COMPONENT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_INSTALLATION_COMPONENT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_INSTALLATION_COMPONENT_ERROR](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_PRODUCT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_PRODUCT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_PRODUCT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_PRODUCT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_PRODUCT_CATEGORY_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_PRODUCT_CATEGORY_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_PRODUCT_CATEGORY_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_PRODUCT_CATEGORY_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_PRODUCT_CATEGORY_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_PRODUCT_CATEGORY_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_PRODUCT_CATEGORY_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_PRODUCT_CATEGORY_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_PRODUCT_COMPONENT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_PRODUCT_COMPONENT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_PRODUCT_COMPONENT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_PRODUCT_COMPONENT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.DELETE_PRODUCT_COMPONENT_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.DELETE_PRODUCT_COMPONENT_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_PRODUCT_SUPPLIER_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_PRODUCT_SUPPLIER_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_PRODUCT_SUPPLIERS_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_PRODUCT_SUPPLIERS_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_PRODUCT_SUPPLIER_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_PRODUCT_SUPPLIER_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_PRODUCT_SUPPLIER_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_PRODUCT_SUPPLIER_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_EMPLOYERS_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_EMPLOYERS_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_EMPLOYERS_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_EMPLOYERS_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_EMPLOYERS_ERROR](state, payload) {
    state.productError = payload;
    state.productQuoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_EMPLOYERS_LOADING](state) {
    state.productError = "";
    state.productQuoteStatus = Status.LOADING;
  },

  clearErrors() {
    state.productError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
