import * as ACTIONS from "@/store/mutation";

import ProleanApiService from "@/services/axios";
// import SessionStorageService from "@/services/sessionStorage";
import { Status } from "@/types";

// import { reactive, ref } from "vue";

const state = () => ({
  status: Status,
  adminRequestStatus: "",
  userError: "",
  clientError: "",
});

// actions
const actions = {
  // Create User
  createUser({ commit }, userCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_USER_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "AdminAccount/createadminuser",
        userCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Activate User
  activateUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.ACTIVATE_USER_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/activateaccount", id)
        .then(({ data }) => {
          // commit(ACTIONS.ACTIVATE_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.ACTIVATE_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Deactivate User
  deactivateUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.DEACTIVATE_USER_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/deactivateaccount", id)
        .then(({ data }) => {
          // commit(ACTIONS.DEACTIVATE_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.DEACTIVATE_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get All Users
  allUsers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_USER_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `AdminAccount/getadminusers?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_USER_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Create Client
  createClient({ commit }, clientCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_CLIENT_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "AdminAccount/clientregistration",
        clientCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_CLIENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_CLIENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Activate Client
  activateClient({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.ACTIVATE_CLIENT_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/activateclient", params)
        // ProleanApiService.postRequest("ClientAccount/activateaccount", params)
        .then(({ data }) => {
          // commit(ACTIONS.ACTIVATE_CLIENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.ACTIVATE_CLIENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Deactivate Client
  deactivateClient({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.DEACTIVATE_CLIENT_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/deactivateclient", params)
        // ProleanApiService.postRequest("ClientAccount/deactivateaccount", params)
        .then(({ data }) => {
          // commit(ACTIONS.DEACTIVATE_CLIENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.DEACTIVATE_CLIENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get All Clients
  allClients({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_CLIENTS_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `Admin/getclientlist?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_CLIENTS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_CLIENTS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Client Details By ID
  clientDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_CLIENT_LOADING, Status.LOADING);
      ProleanApiService.getRequest(`Admin/getclientdetailsbyid?clientId=${id}`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_CLIENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_CLIENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Admin Get Client Users
  adminGetClientUsers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.ADMIN_GET_CLIENT_USERS_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `Admin/getclientusersbyclientid?clientId=${parameters.clientId}&pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.ADMIN_GET_CLIENT_USERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.ADMIN_GET_CLIENT_USERS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Client Details
  clientDetails({ commit }) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_CLIENT_LOADING, Status.LOADING);
      ProleanApiService.getRequest(`ClientAccount/getclientdetails`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_CLIENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_CLIENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Create Client User
  createClientUser({ commit }, clientCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_CLIENT_USER_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "ClientAccount/createuser",
        clientCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_CLIENT_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_CLIENT_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get All Clients Users
  allClientsUsers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_CLIENT_USERS_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientAccount/getadminaccounts?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_CLIENT_USERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_CLIENT_USERS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Activate Client User
  activateClientUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.ACTIVATE_CLIENT_USER_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "ClientAccount/activateclientaccount",
        params
      )
        .then(({ data }) => {
          // commit(ACTIONS.ACTIVATE_CLIENT_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.ACTIVATE_CLIENT_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Deactivate Client User
  deactivateClientUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.DEACTIVATE_CLIENT_USER_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "ClientAccount/deactivateclientaccount",
        params
      )
        .then(({ data }) => {
          // commit(ACTIONS.DEACTIVATE_CLIENT_USER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.DEACTIVATE_CLIENT_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [ACTIONS.CREATE_USER_ERROR](state, payload) {
    state.userError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_USER_LOADING](state) {
    state.userError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.ACTIVATE_USER_ERROR](state, payload) {
    state.userError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.ACTIVATE_USER_LOADING](state) {
    state.userError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.DEACTIVATE_USER_ERROR](state, payload) {
    state.userError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.DEACTIVATE_USER_LOADING](state) {
    state.userError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_USER_ERROR](state, payload) {
    state.userError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_USER_LOADING](state) {
    state.userError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  // Client Mutation
  [ACTIONS.CREATE_CLIENT_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_CLIENT_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.ACTIVATE_CLIENT_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.ACTIVATE_CLIENT_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.DEACTIVATE_CLIENT_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.DEACTIVATE_CLIENT_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_CLIENTS_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_CLIENTS_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_CLIENT_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_CLIENT_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.ADMIN_GET_CLIENT_USERS_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.ADMIN_GET_CLIENT_USERS_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  // Client Users Mutation
  [ACTIONS.CREATE_CLIENT_USER_ERROR](state, payload) {
    state.userError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_CLIENT_USER_LOADING](state) {
    state.userError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_CLIENT_USERS_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_CLIENT_USERS_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.ACTIVATE_CLIENT_USER_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.ACTIVATE_CLIENT_USER_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  [ACTIONS.DEACTIVATE_CLIENT_USER_ERROR](state, payload) {
    state.clientError = payload;
    state.adminRequestStatus = Status.ERROR;
  },
  [ACTIONS.DEACTIVATE_CLIENT_USER_LOADING](state) {
    state.clientError = "";
    state.adminRequestStatus = Status.LOADING;
  },
  clearErrors() {
    state.userError = "";
    state.clientError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
