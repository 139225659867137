import * as ACTIONS from "@/store/mutation";

import ProleanApiService from "@/services/axios";
// import SessionStorageService from "@/services/sessionStorage";
import { Status } from "@/types";

// import { reactive, ref } from "vue";

const state = () => ({
  status: Status,
  mandateRequestStatus: "",
  mandateError: "",
});

// actions
const actions = {
  // Create Mandate
  create({ commit }, mandateCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_MANDATE_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "ClientNibssMandate/create",
        mandateCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_MANDATE_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_MANDATE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get All Mandates
  allMandates({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MANDATES_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/clientmandatelist?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MANDATES_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_MANDATES_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get All Mandates By Payer Name
  allMandatesByPayerName({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MANDATES_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/mandatebypayername?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}&payerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MANDATES_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_MANDATES_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Exports Data
  exports({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      ProleanApiService.getRequest(
        `ClientNibssMandate/clientmandatelist?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  // Mandate Details By Code
  mandateByCode({ commit }, code) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MANDATE_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/mandatedetailsbymandatecode?mandateCode=${code}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MANDATE_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_MANDATE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Mandate Details By ID
  mandateById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MANDATE_BY_ID_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/mandatedetailsbyid?mandateId=${id}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MANDATE_BY_ID_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_MANDATE_BY_ID_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get All Mandate Successful Collections By Mandate ID
  allMandateCollections({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MANDATE_COLLECTIONS_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/collectionsbymandateid?mandateId=${parameters.mandateId}&pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MANDATE_COLLECTIONS_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_MANDATE_COLLECTIONS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Mandate Successful Collection By ID
  mandateCollectionById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.COLLECTION_BY_ID_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/mandatecollectionsbyid?mandateCollectionId=${id}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.COLLECTION_BY_ID_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.COLLECTION_BY_ID_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Mandate Status
  mandateStatus({ commit }, mandateCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.MANDATE_STATUS_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "ClientNibssMandate/status",
        mandateCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.MANDATE_STATUS_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.MANDATE_STATUS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Stop Running Mandate
  stopRunningMandate({ commit }, mandateCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.STOP_RUNNING_MANDATE_LOADING, Status.LOADING);
      ProleanApiService.putRequest(
        "ClientNibssMandate/stoprunningmandate",
        mandateCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.STOP_RUNNING_MANDATE_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.STOP_RUNNING_MANDATE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Start Running Mandate
  startRunningMandate({ commit }, mandateCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.START_RUNNING_MANDATE_LOADING, Status.LOADING);
      ProleanApiService.putRequest(
        "ClientNibssMandate/startrunningmandate",
        mandateCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.START_RUNNING_MANDATE_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.START_RUNNING_MANDATE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Mandate Name Enquiry
  mandateNameEnquiry({ commit }, enquiryCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.NAME_ENQUIRY_LOADING, Status.LOADING);
      ProleanApiService.postRequest(
        "ClientNibssMandate/nameenquiry",
        enquiryCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.NAME_ENQUIRY_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.NAME_ENQUIRY_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Mandate Repayment Schedule
  mandateRepaymentSchedule({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.REPAYMENT_SCHEDULE_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/mandatepaymentschedule?collectionDay=${parameters.collectionDay}&startDate=${parameters.startDate}&endDate=${parameters.endDate}&amount=${parameters.amount}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.REPAYMENT_SCHEDULE_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.REPAYMENT_SCHEDULE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Mandate Repayment Schedule By ID
  mandateRepaymentScheduleByID({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.REPAYMENT_SCHEDULE_BY_ID_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `ClientNibssMandate/mandateschedulebymandateid?mandateId=${parameters.mandateId}&pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.REPAYMENT_SCHEDULE_BY_ID_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.REPAYMENT_SCHEDULE_BY_ID_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  [ACTIONS.CREATE_MANDATE_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_MANDATE_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_MANDATES_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_MANDATES_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_MANDATE_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_MANDATE_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_MANDATE_BY_ID_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_MANDATE_BY_ID_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.MANDATE_STATUS_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.MANDATE_STATUS_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.STOP_RUNNING_MANDATE_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.STOP_RUNNING_MANDATE_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.START_RUNNING_MANDATE_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.START_RUNNING_MANDATE_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_MANDATE_COLLECTIONS_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_MANDATE_COLLECTIONS_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.COLLECTION_BY_ID_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.COLLECTION_BY_ID_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.NAME_ENQUIRY_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.NAME_ENQUIRY_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.REPAYMENT_SCHEDULE_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.REPAYMENT_SCHEDULE_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  [ACTIONS.REPAYMENT_SCHEDULE_BY_ID_ERROR](state, payload) {
    state.mandateError = payload;
    state.mandateRequestStatus = Status.ERROR;
  },
  [ACTIONS.REPAYMENT_SCHEDULE_BY_ID_LOADING](state) {
    state.mandateError = "";
    state.mandateRequestStatus = Status.LOADING;
  },
  clearErrors() {
    state.mandateError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
