export const CREATE_FINANCIAL_INSTITUTION_LOADING =
  "CREATE_FINANCIAL_INSTITUTION_LOADING";
export const CREATE_FINANCIAL_INSTITUTION_SUCCESS =
  "CREATE_FINANCIAL_INSTITUTION_SUCCESS";
export const CREATE_FINANCIAL_INSTITUTION_ERROR =
  "CREATE_FINANCIAL_INSTITUTION_ERROR";

export const UPDATE_FINANCIAL_INSTITUTION_LOADING =
  "UPDATE_FINANCIAL_INSTITUTION_LOADING";
export const UPDATE_FINANCIAL_INSTITUTION_SUCCESS =
  "UPDATE_FINANCIAL_INSTITUTION_SUCCESS";
export const UPDATE_FINANCIAL_INSTITUTION_ERROR =
  "UPDATE_FINANCIAL_INSTITUTION_ERROR";

export const GET_ALL_FINANCIAL_INSTITUTION_LOADING =
  "GET_ALL_FINANCIAL_INSTITUTION_LOADING";
export const GET_ALL_FINANCIAL_INSTITUTION_SUCCESS =
  "GET_ALL_FINANCIAL_INSTITUTION_SUCCESS";
export const GET_ALL_FINANCIAL_INSTITUTION_ERROR =
  "GET_ALL_FINANCIAL_INSTITUTION_ERROR";

export const GET_FINANCIAL_INSTITUTION_LOADING =
  "GET_FINANCIAL_INSTITUTION_LOADING";
export const GET_FINANCIAL_INSTITUTION_SUCCESS =
  "GET_FINANCIAL_INSTITUTION_SUCCESS";
export const GET_FINANCIAL_INSTITUTION_ERROR =
  "GET_FINANCIAL_INSTITUTION_ERROR";

export const CREATE_FINANCIAL_INSTITUTION_QUOTE_LOADING =
  "CREATE_FINANCIAL_INSTITUTION_QUOTE_LOADING";
export const CREATE_FINANCIAL_INSTITUTION_QUOTE_SUCCESS =
  "CREATE_FINANCIAL_INSTITUTION_QUOTE_SUCCESS";
export const CREATE_FINANCIAL_INSTITUTION_QUOTE_ERROR =
  "CREATE_FINANCIAL_INSTITUTION_QUOTE_ERROR";

export const UPDATE_FINANCIAL_INSTITUTION_QUOTE_LOADING =
  "UPDATE_FINANCIAL_INSTITUTION_QUOTE_LOADING";
export const UPDATE_FINANCIAL_INSTITUTION_QUOTE_SUCCESS =
  "UPDATE_FINANCIAL_INSTITUTION_QUOTE_SUCCESS";
export const UPDATE_FINANCIAL_INSTITUTION_QUOTE_ERROR =
  "UPDATE_FINANCIAL_INSTITUTION_QUOTE_ERROR";

export const GET_FINANCIAL_INSTITUTIONS_QUOTE_LOADING =
  "GET_FINANCIAL_INSTITUTIONS_QUOTE_LOADING";
export const GET_FINANCIAL_INSTITUTIONS_QUOTE_SUCCESS =
  "GET_FINANCIAL_INSTITUTIONS_QUOTE_SUCCESS";
export const GET_FINANCIAL_INSTITUTIONS_QUOTE_ERROR =
  "GET_FINANCIAL_INSTITUTIONS_QUOTE_ERROR";

export const GET_FINANCIAL_INSTITUTION_QUOTE_ID_LOADING =
  "GET_FINANCIAL_INSTITUTION_QUOTE_ID_LOADING";
export const GET_FINANCIAL_INSTITUTION_QUOTE_ID_SUCCESS =
  "GET_FINANCIAL_INSTITUTION_QUOTE_ID_SUCCESS";
export const GET_FINANCIAL_INSTITUTION_QUOTE_ID_ERROR =
  "GET_FINANCIAL_INSTITUTION_QUOTE_ID_ERROR";

export const GET_BANK_USERS_LOADING = "GET_BANK_USERS_LOADING";
export const GET_BANK_USERS_SUCCESS = "GET_BANK_USERS_SUCCESS";
export const GET_BANK_USERS_ERROR = "GET_BANK_USERS_ERROR";

export const CREATE_BANK_USERS_LOADING = "CREATE_BANK_USERS_LOADING";
export const CREATE_BANK_USERS_SUCCESS = "CREATE_BANK_USERS_SUCCESS";
export const CREATE_BANK_USERS_ERROR = "CREATE_BANK_USERS_ERROR";

export const APPROVE_QUOTE_LOADING = "APPROVE_QUOTE_LOADING";
export const APPROVE_QUOTE_SUCCESS = "APPROVE_QUOTE_SUCCESS";
export const APPROVE_QUOTE_ERROR = "APPROVE_QUOTE_ERROR";
