import * as ACTIONS from "./../financial.mutation";

// import { reactive, ref } from "vue";
import QuoteApiService from "@/services/quote.axios";
// import SessionStorageService from "@/services/sessionStorage";
import { Status } from "@/types";

const state = () => ({
  status: Status,
  financialStatus: "",
  financialError: "",
});

// actions
const actions = {
  // create financial institution
  createFinancialInstitution({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_FINANCIAL_INSTITUTION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "FinancialInstitution/CreateFinancialInstitution",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_FINANCIAL_INSTITUTION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.CREATE_FINANCIAL_INSTITUTION_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // update financial institution
  updateFinancialInstitution({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_FINANCIAL_INSTITUTION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "FinancialInstitution/UpdateFinancialInstitution",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_FINANCIAL_INSTITUTION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.UPDATE_FINANCIAL_INSTITUTION_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // Get all financial institutions
  allFinancialInstitutions({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_FINANCIAL_INSTITUTION_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `FinancialInstitution/FinancialInstitutions?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_FINANCIAL_INSTITUTION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_FINANCIAL_INSTITUTION_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Financial Institution by ID
  financialInstitutionById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_FINANCIAL_INSTITUTION_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `FinancialInstitution/FinancialInstitutionById?id=${id}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_FINANCIAL_INSTITUTION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_FINANCIAL_INSTITUTION_ERROR,
            response?.data?.errors ?? { data: "Request failed" }
          );
          reject(response ?? { data: "Request failed" });
        });
    });
  },

  // create financial institution quote
  createFinancialInstitutionQuote({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(
        ACTIONS.CREATE_FINANCIAL_INSTITUTION_QUOTE_LOADING,
        Status.LOADING
      );
      QuoteApiService.postRequest(
        "FinancialInstitutionQuote/CreateFinancialInstitutionQuote",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_FINANCIAL_INSTITUTION_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.CREATE_FINANCIAL_INSTITUTION_QUOTE_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // update financial institution quote
  updateFinancialInstitutionQuote({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(
        ACTIONS.UPDATE_FINANCIAL_INSTITUTION_QUOTE_LOADING,
        Status.LOADING
      );
      QuoteApiService.postRequest(
        "FinancialInstitutionQuote/UpdateFinancialInstitutionQuote",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_FINANCIAL_INSTITUTION_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.UPDATE_FINANCIAL_INSTITUTION_QUOTE_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // Get financial institutions quote
  financialInstitutionsQuote({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_FINANCIAL_INSTITUTIONS_QUOTE_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        // `FinancialInstitutionQuote/FinancialInstitutionsQuote?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
        `Quote/GetAwaitingCustomerResponseQuotesForBanks?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_FINANCIAL_INSTITUTIONS_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_FINANCIAL_INSTITUTIONS_QUOTE_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Financial Institution Quote by ID
  financialInstitutionQuoteById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(
        ACTIONS.GET_FINANCIAL_INSTITUTION_QUOTE_ID_LOADING,
        Status.LOADING
      );
      QuoteApiService.getRequest(
        `FinancialInstitutionQuote/FinancialInstitutionQuoteById?id=${id}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_FINANCIAL_INSTITUTION_QUOTE_ID_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_FINANCIAL_INSTITUTION_QUOTE_ID_ERROR,
            response?.data?.errors ?? { data: "Request failed" }
          );
          reject(response ?? { data: "Request failed" });
        });
    });
  },

  // Approve Financial Quote
  quoteApproval({ commit }, quoteCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.APPROVE_QUOTE_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Quote/SetFinancialInstitutionQuoteStatusLoanApproved",
        quoteCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.APPROVE_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.APPROVE_QUOTE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all Bank users
  allBanksUsers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_BANK_USERS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `ClientUser/GetSupportUsers?UserRole=${parameters.role}&PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_BANK_USERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_BANK_USERS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // create bank user
  createBankUser({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_BANK_USERS_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "ClientUser/CreateFinancialInsitutionUser",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_BANK_USERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_BANK_USERS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  // Financial Institution
  [ACTIONS.CREATE_FINANCIAL_INSTITUTION_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_FINANCIAL_INSTITUTION_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_FINANCIAL_INSTITUTION_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_FINANCIAL_INSTITUTION_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_FINANCIAL_INSTITUTION_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_FINANCIAL_INSTITUTION_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.GET_FINANCIAL_INSTITUTION_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.GET_FINANCIAL_INSTITUTION_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  // Financial Institution Quote
  [ACTIONS.CREATE_FINANCIAL_INSTITUTION_QUOTE_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_FINANCIAL_INSTITUTION_QUOTE_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_FINANCIAL_INSTITUTION_QUOTE_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_FINANCIAL_INSTITUTION_QUOTE_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.GET_FINANCIAL_INSTITUTIONS_QUOTE_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.GET_FINANCIAL_INSTITUTIONS_QUOTE_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.APPROVE_QUOTE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.APPROVE_QUOTE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  // Financial Institution Users
  [ACTIONS.GET_BANK_USERS_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.GET_BANK_USERS_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_BANK_USERS_ERROR](state, payload) {
    state.financialError = payload;
    state.financialStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_BANK_USERS_LOADING](state) {
    state.financialError = "";
    state.financialStatus = Status.LOADING;
  },

  clearErrors() {
    state.quoteError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
