export const lOGING_LOADING = "LOGGING_LOADING";
export const LOGGING_SUCCESS = "LOGGING_SUCCESS";
export const LOGGING_ERROR = "LOGGING_ERROR";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const REMOVE_AUTH = "REMOVE_AUTH";

export const CREATE_USER_LOADING = "CREATE_USER_LOADING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const ACTIVATE_USER_LOADING = "ACTIVATE_USER_LOADING";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_ERROR = "ACTIVATE_USER_ERROR";

export const DEACTIVATE_USER_LOADING = "DEACTIVATE_USER_LOADING";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_ERROR = "DEACTIVATE_USER_ERROR";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const CREATE_CLIENT_LOADING = "CREATE_CLIENT_LOADING";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR";

export const ACTIVATE_CLIENT_LOADING = "ACTIVATE_CLIENT_LOADING";
export const ACTIVATE_CLIENT_SUCCESS = "ACTIVATE_CLIENT_SUCCESS";
export const ACTIVATE_CLIENT_ERROR = "ACTIVATE_CLIENT_ERROR";

export const DEACTIVATE_CLIENT_LOADING = "DEACTIVATE_CLIENT_LOADING";
export const DEACTIVATE_CLIENT_SUCCESS = "DEACTIVATE_CLIENT_SUCCESS";
export const DEACTIVATE_CLIENT_ERROR = "DEACTIVATE_CLIENT_ERROR";

export const GET_CLIENTS_LOADING = "GET_CLIENTS_LOADING";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR";

export const GET_CLIENT_LOADING = "GET_CLIENT_LOADING";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_ERROR = "GET_CLIENT_ERROR";

export const CREATE_MANDATE_LOADING = "CREATE_MANDATE_LOADING";
export const CREATE_MANDATE_SUCCESS = "CREATE_MANDATE_SUCCESS";
export const CREATE_MANDATE_ERROR = "CREATE_MANDATE_ERROR";

export const MANDATE_STATUS_LOADING = "MANDATE_STATUS_LOADING";
export const MANDATE_STATUS_SUCCESS = "MANDATE_STATUS_SUCCESS";
export const MANDATE_STATUS_ERROR = "MANDATE_STATUS_ERROR";

export const GET_MANDATES_LOADING = "GET_MANDATES_LOADING";
export const GET_MANDATES_SUCCESS = "GET_MANDATES_SUCCESS";
export const GET_MANDATES_ERROR = "GET_MANDATES_ERROR";

export const GET_MANDATE_LOADING = "GET_MANDATE_LOADING";
export const GET_MANDATE_SUCCESS = "GET_MANDATE_SUCCESS";
export const GET_MANDATE_ERROR = "GET_MANDATE_ERROR";

export const GET_MANDATE_BY_ID_LOADING = "GET_MANDATE_BY_ID_LOADING";
export const GET_MANDATE_BY_ID_SUCCESS = "GET_MANDATE_BY_ID_SUCCESS";
export const GET_MANDATE_BY_ID_ERROR = "GET_MANDATE_BY_ID_ERROR";

export const STOP_RUNNING_MANDATE_LOADING = "STOP_RUNNING_MANDATE_LOADING";
export const STOP_RUNNING_MANDATE_SUCCESS = "STOP_RUNNING_MANDATE_SUCCESS";
export const STOP_RUNNING_MANDATE_ERROR = "STOP_RUNNING_MANDATE_ERROR";

export const START_RUNNING_MANDATE_LOADING = "START_RUNNING_MANDATE_LOADING";
export const START_RUNNING_MANDATE_SUCCESS = "START_RUNNING_MANDATE_SUCCESS";
export const START_RUNNING_MANDATE_ERROR = "START_RUNNING_MANDATE_ERROR";

export const CREATE_BANK_LOADING = "CREATE_BANK_LOADING";
export const CREATE_BANK_SUCCESS = "CREATE_BANK_SUCCESS";
export const CREATE_BANK_ERROR = "CREATE_BANK_ERROR";

export const UPDATE_BANK_LOADING = "UPDATE_BANK_LOADING";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_ERROR = "UPDATE_BANK_ERROR";

export const GET_BANKS_LOADING = "GET_BANKS_LOADING";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_ERROR = "GET_BANKS_ERROR";

export const UPDATE_CLIENT_BANK_LOADING = "UPDATE_CLIENT_BANK_LOADING";
export const UPDATE_CLIENT_BANK_SUCCESS = "UPDATE_CLIENT_BANK_SUCCESS";
export const UPDATE_CLIENT_BANK_ERROR = "UPDATE_CLIENT_BANK_ERROR";

export const CREATE_CLIENT_USER_LOADING = "CREATE_CLIENT_USER_LOADING";
export const CREATE_CLIENT_USER_SUCCESS = "CREATE_CLIENT_USER_SUCCESS";
export const CREATE_CLIENT_USER_ERROR = "CREATE_CLIENT_USER_ERROR";

export const GET_CLIENT_USERS_LOADING = "GET_CLIENT_USERS_LOADING";
export const GET_CLIENT_USERS_SUCCESS = "GET_CLIENT_USERS_SUCCESS";
export const GET_CLIENT_USERS_ERROR = "GET_CLIENT_USERS_ERROR";

export const ACTIVATE_CLIENT_USER_LOADING = "ACTIVATE_CLIENT_USER_LOADING";
export const ACTIVATE_CLIENT_USER_SUCCESS = "ACTIVATE_CLIENT_USER_SUCCESS";
export const ACTIVATE_CLIENT_USER_ERROR = "ACTIVATE_CLIENT_USER_ERROR";

export const DEACTIVATE_CLIENT_USER_LOADING = "DEACTIVATE_CLIENT_USER_LOADING";
export const DEACTIVATE_CLIENT_USER_SUCCESS = "DEACTIVATE_CLIENT_USER_SUCCESS";
export const DEACTIVATE_CLIENT_USER_ERROR = "DEACTIVATE_CLIENT_USER_ERROR";

export const ADMIN_GET_CLIENT_USERS_LOADING = "ADMIN_GET_CLIENT_USERS_LOADING";
export const ADMIN_GET_CLIENT_USERS_SUCCESS = "ADMIN_GET_CLIENT_USERS_SUCCESS";
export const ADMIN_GET_CLIENT_USERS_ERROR = "ADMIN_GET_CLIENT_USERS_ERROR";

export const GET_MANDATE_COLLECTIONS_LOADING =
  "GET_MANDATE_COLLECTIONS_LOADING";
export const GET_MANDATE_COLLECTIONS_SUCCESS =
  "GET_MANDATE_COLLECTIONS_SUCCESS";
export const GET_MANDATE_COLLECTIONS_ERROR = "GET_MANDATE_COLLECTIONS_ERROR";

export const COLLECTION_BY_ID_LOADING = "COLLECTION_BY_ID_LOADING";
export const COLLECTION_BY_ID_SUCCESS = "COLLECTION_BY_ID_SUCCESS";
export const COLLECTION_BY_ID_ERROR = "COLLECTION_BY_ID_ERROR";

export const NAME_ENQUIRY_LOADING = "NAME_ENQUIRY_LOADING";
export const NAME_ENQUIRY_SUCCESS = "NAME_ENQUIRY_SUCCESS";
export const NAME_ENQUIRY_ERROR = "NAME_ENQUIRY_ERROR";

export const REPAYMENT_SCHEDULE_LOADING = "REPAYMENT_SCHEDULE_LOADING";
export const REPAYMENT_SCHEDULE_SUCCESS = "REPAYMENT_SCHEDULE_SUCCESS";
export const REPAYMENT_SCHEDULE_ERROR = "REPAYMENT_SCHEDULE_ERROR";

export const REPAYMENT_SCHEDULE_BY_ID_LOADING =
  "REPAYMENT_SCHEDULE_BY_ID_LOADING";
export const REPAYMENT_SCHEDULE_BY_ID_SUCCESS =
  "REPAYMENT_SCHEDULE_BY_ID_SUCCESS";
export const REPAYMENT_SCHEDULE_BY_ID_ERROR = "REPAYMENT_SCHEDULE_BY_ID_ERROR";
