import * as ACTIONS from "./../quote.mutation";

import QuoteApiService from "@/services/quote.axios";
import { Status } from "@/types";

const state = () => ({
  status: Status,
  inventoryStatus: "",
  inventoryError: "",
  purchaseItems: null,
  distributionItems: null,
});

// actions
const actions = {
  // Create Inward Purchase
  createInwardPurchase({ commit }, purchaseCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_INWARD_PURCHASE_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Inventory/CreateInwardPurchase",
        purchaseCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_INWARD_PURCHASE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_INWARD_PURCHASE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get Inward Purchases
  getInwardPurchases({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INWARD_PURCHASES_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Inventory/GetInwardPurchases?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_INWARD_PURCHASES_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_INWARD_PURCHASES_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get Inward Purchase by purchaseId
  getInwardPurchaseById({ commit }, purchaseId) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INWARD_PURCHASE_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Inventory/GetInwardPurchaseDetails?purchaseId=${purchaseId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_INWARD_PURCHASE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_INWARD_PURCHASE_ERROR,
            response?.data?.errors ?? { data: "Request failed" }
          );
          reject(response ?? { data: "Request failed" });
        });
    });
  },

  // Get Inventory Stocks
  getInventoryStocks({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INVENTORY_STOCKS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Inventory/GetInventoryStock?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}&Parameter=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_INVENTORY_STOCKS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_INVENTORY_STOCKS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get Inventory Stock by componentId
  getInventoryStockById({ commit }, stockId) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INVENTORY_STOCK_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Inventory/GetInventoryStockDetails?id=${stockId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_INVENTORY_STOCK_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_INVENTORY_STOCK_ERROR,
            response?.data?.errors ?? { data: "Request failed" }
          );
          reject(response ?? { data: "Request failed" });
        });
    });
  },

  // Create Distribution Component
  createDistributionComponent({ commit }, distributionCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_DISTRIBUTION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Inventory/DistributeComponents",
        distributionCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_DISTRIBUTION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_DISTRIBUTION_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get Distribution Components
  getDistributionComponents({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_DISTRIBUTIONS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Inventory/GetComponentsDistributions?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}&PurchaseNumber=${parameters.purchaseNumber}`
      )
        .then(({ data }) => {
          commit(ACTIONS.GET_DISTRIBUTIONS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_DISTRIBUTIONS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get Distribution Component by distributionId
  getDistributionComponentById({ commit }, distributionId) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_DISTRIBUTION_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `Inventory/GetComponentsDistributionDetails?distributionId=${distributionId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_DISTRIBUTION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_DISTRIBUTION_ERROR,
            response?.data?.errors ?? { data: "Request failed" }
          );
          reject(response ?? { data: "Request failed" });
        });
    });
  },

  // Batch Assign Location to Components
  batchAssignLocationToComponents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.BATCH_ASSIGN_LOCATION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Inventory/AssignComponentToStateAfterInstallation",
        payload
      )
        .then(({ data }) => {
          // commit(ACTIONS.BATCH_ASSIGN_LOCATION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.BATCH_ASSIGN_LOCATION_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [ACTIONS.CREATE_INWARD_PURCHASE_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_INWARD_PURCHASE_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.GET_INWARD_PURCHASES_SUCCESS](state, data) {
    state.purchaseItems = data.data;
    state.inventoryStatus = Status.SUCCESS;
  },
  [ACTIONS.GET_INWARD_PURCHASES_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.GET_INWARD_PURCHASES_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.GET_INWARD_PURCHASE_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.GET_INWARD_PURCHASE_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.GET_INVENTORY_STOCKS_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.GET_INVENTORY_STOCKS_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.GET_INVENTORY_STOCK_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.GET_INVENTORY_STOCK_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_DISTRIBUTION_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_DISTRIBUTION_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.GET_DISTRIBUTIONS_SUCCESS](state, data) {
    state.distributionItems = data.data;
    state.inventoryStatus = Status.SUCCESS;
  },
  [ACTIONS.GET_DISTRIBUTIONS_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.GET_DISTRIBUTIONS_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.GET_DISTRIBUTION_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.GET_DISTRIBUTION_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  [ACTIONS.BATCH_ASSIGN_LOCATION_ERROR](state, payload) {
    state.inventoryError = payload;
    state.inventoryStatus = Status.ERROR;
  },
  [ACTIONS.BATCH_ASSIGN_LOCATION_LOADING](state) {
    state.inventoryError = "";
    state.inventoryStatus = Status.LOADING;
  },

  clearErrors() {
    state.inventoryError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
