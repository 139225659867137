import SessionStorageService from "@/services/sessionStorage";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const axiosInstance = axios.create({});

let refreshToken = null;

const authHeader = () => {
  let auth = SessionStorageService.getItem("token");
  if (auth) return auth;
  return "";
};

/* eslint-disable  no-unused-vars */
/* eslint-disable  no-undef */

/**
 * Set the default HTTP request headers
 */
const setHeader = (token = null) => {
  axios.defaults.baseURL = process.env.VUE_APP_GREEN_ENERGY_BASE_URL;
  axios.defaults.headers.common = {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
    accept: "Accept: application/json",
    Authorization: `Bearer ${token || authHeader()}`,
  };
};

/**
 * Service to call HTTP request via Axios
 */
const QuoteApiService = {
  init(store) {
    const maxRetryTime = 3;
    let counter = 0;

    axiosInstance.interceptors.request.use(
      (config) => {
        const token = SessionStorageService.getItem("token");

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },

      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },

      async (error) => {
        const originalRequest = error.config;

        if (counter < maxRetryTime && error.response.status === 401) {
          refreshToken = null;
          counter++;
          const userId = store.state.auth.authenticatedUser.user.id;
          const refreshT = store.state.auth.authenticatedUser.refreshToken;

          refreshToken = await store.dispatch("auth/refreshToken", {
            userId,
            refreshToken: refreshT,
          });

          if (refreshToken.status) {
            const token = SessionStorageService.getItem("token");
            originalRequest.headers["Authorization"] = `Bearer ${token}`;

            return new Promise((resolve) => {
              return resolve(axiosInstance(originalRequest));
            });
          }
        }

        const hasRefreshToken = error.config.url.includes(
          "ClientUser/RefreshUserToken"
        );

        if (hasRefreshToken) {
          store.dispatch("auth/logout");
          router.push("/login");

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  },

  query(resource, params) {
    return axios.get(resource, params);
  },

  getRequest(resource, slug = "") {
    setHeader();
    if (slug == null || slug == "") {
      return axios.get(`${resource}`).catch((error) => {
        throw new Error(`${error}`);
      });
    } else {
      return axios.get(`${resource}/${slug}`).catch((error) => {
        throw new Error(`${error}`);
      });
    }
  },

  getFormData(resource, slug = "") {
    setHeader();
    if (slug == null || slug == "") {
      return axios
        .get(`${resource}`, { responseType: "blob" })
        .catch((error) => {
          throw new Error(`${error}`);
        });
    } else {
      return axios
        .get(`${resource}/${slug}`, { responseType: "blob" })
        .catch((error) => {
          throw new Error(`${error}`);
        });
    }
  },

  postRequest(resource, params) {
    if (resource.endsWith("ChangePassword")) {
      setHeader(SessionStorageService.getCPItem("CpToken"));
    } else {
      setHeader();
    }
    // setHeader();
    return axios.post(`${resource}`, params);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postFormData(resource, params) {
    setHeader();
    return axios.post(`${resource}`, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  putRequest(resource, params) {
    setHeader();
    return axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    setHeader();
    return axios.delete(`${resource}`).catch((error) => {
      throw error;
    });
  },
};

export default QuoteApiService;
