import * as ACTIONS from "@/store/mutation";

import ProleanApiService from "@/services/axios";
import { Status } from "@/types";

const state = () => ({
  status: Status,
  bankRequestStatus: "",
  bankError: "",
});

// actions
const actions = {
  // Create Bank
  create({ commit }, bankCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_BANK_LOADING, Status.LOADING);
      ProleanApiService.postRequest("Banks/create", bankCredentials)
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_BANK_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_BANK_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Update Bank
  update({ commit }, bankCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_BANK_LOADING, Status.LOADING);
      ProleanApiService.putRequest("Banks/update", bankCredentials)
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_BANK_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_BANK_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Get all Bank
  bankList({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_BANKS_LOADING, Status.LOADING);
      ProleanApiService.getRequest(
        `Banks/getbankslist?pageNumber=${parameters.pageIndex}&pageSize=${parameters.pageSize}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_BANKS_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_BANKS_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  // Update Client Bank By Client Id
  updateClientBank({ commit }, bankCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_CLIENT_BANK_LOADING, Status.LOADING);
      ProleanApiService.putRequest("Client/updateclientbank", bankCredentials)
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_CLIENT_BANK_SUCCESS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_CLIENT_BANK_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  [ACTIONS.CREATE_BANK_ERROR](state, payload) {
    state.bankError = payload;
    state.bankRequestStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_BANK_LOADING](state) {
    state.bankError = "";
    state.bankRequestStatus = Status.LOADING;
  },
  [ACTIONS.UPDATE_BANK_ERROR](state, payload) {
    state.bankError = payload;
    state.bankRequestStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_BANK_LOADING](state) {
    state.bankError = "";
    state.bankRequestStatus = Status.LOADING;
  },
  [ACTIONS.GET_BANKS_ERROR](state, payload) {
    state.bankError = payload;
    state.bankRequestStatus = Status.ERROR;
  },
  [ACTIONS.GET_BANKS_LOADING](state) {
    state.bankError = "";
    state.bankRequestStatus = Status.LOADING;
  },
  [ACTIONS.UPDATE_CLIENT_BANK_ERROR](state, payload) {
    state.bankError = payload;
    state.bankRequestStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_CLIENT_BANK_LOADING](state) {
    state.bankError = "";
    state.bankRequestStatus = Status.LOADING;
  },
  clearErrors() {
    state.bankError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
