import * as ACTIONS from "@/store/mutation";
import * as ACTIONSS from "@/store/quote.mutation";

import { reactive, ref } from "vue";

import ProleanApiService from "@/services/axios";
import QuoteApiService from "@/services/quote.axios";
import SessionStorageService from "@/services/sessionStorage";
import { Status } from "@/types";

const IS_USER_AUTHENTICATED = "IS_USER_AUTHENTICATED";

const state = () => ({
  status: Status,
  authRequestStatus: "",
  authenticatedUser: {},
  loginError: "",
  resetError: "",
  changeError: "",
});

// getters
const getters = {
  [IS_USER_AUTHENTICATED]: (state) => {
    return !!state.authenticatedUser.jwtToken;
  },
  isLoggedIn: (state) => state.authRequestStatus === Status.SUCCESS,
};

// actions
const actions = {
  // quote login
  quoteLogin({ commit }, loginCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONSS.LOGIN_USER_LOADING, Status.LOADING);
      QuoteApiService.postRequest("ClientUser/Login", loginCredentials)
        .then(({ data }) => {
          if (!data.user.isFirstTimeUser) {
            commit(ACTIONSS.LOGIN_USER_SUCCESS, data);
          } else {
            SessionStorageService.setItem("CpToken", data.token);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONSS.LOGIN_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // quote change password
  quoteChangePassword({ commit }, changeCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONSS.LOGIN_USER_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "ClientUser/ChangePassword",
        changeCredentials
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONSS.LOGIN_USER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // quote refresh token
  refreshToken({ commit }, refreshCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONSS.REFRESH_USER_TOKEN_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "ClientUser/RefreshUserToken",
        refreshCredentials
      )
        .then(({ data }) => {
          commit(ACTIONSS.REFRESH_USER_TOKEN_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONSS.REFRESH_USER_TOKEN_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // mandate client login
  login({ commit }, loginCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.lOGING_LOADING, Status.LOADING);
      ProleanApiService.postRequest("ClientAccount/login", loginCredentials)
        .then(({ data }) => {
          if (data.hasChangedPassword) {
            commit(ACTIONS.LOGGING_SUCCESS, data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.LOGGING_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // mandate admin login
  adminLogin({ commit }, loginCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.lOGING_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/login", loginCredentials)
        .then(({ data }) => {
          if (data.hasChangedPassword) {
            commit(ACTIONS.LOGGING_SUCCESS, data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.LOGGING_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // mandate client reset password
  reset({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.RESET_PASSWORD_LOADING, Status.LOADING);
      ProleanApiService.postRequest("ClientAccount/resetpassword", credentials)
        .then(({ data }) => {
          commit(ACTIONS.RESET_PASSWORD_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.RESET_PASSWORD_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // mandate admin reset password
  adminReset({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.RESET_PASSWORD_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/resetpassword", credentials)
        .then(({ data }) => {
          commit(ACTIONS.RESET_PASSWORD_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.RESET_PASSWORD_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // mandate client change password
  change({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CHANGE_PASSWORD_LOADING, Status.LOADING);
      ProleanApiService.postRequest("ClientAccount/changepassword", credentials)
        .then(({ data }) => {
          commit(ACTIONS.CHANGE_PASSWORD_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CHANGE_PASSWORD_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // mandate admin change password
  adminChange({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CHANGE_PASSWORD_LOADING, Status.LOADING);
      ProleanApiService.postRequest("AdminAccount/changepassword", credentials)
        .then(({ data }) => {
          commit(ACTIONS.CHANGE_PASSWORD_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CHANGE_PASSWORD_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // log out
  logout({ commit }) {
    commit(ACTIONS.REMOVE_AUTH);
  },
};

// mutations
const mutations = {
  [ACTIONSS.LOGIN_USER_SUCCESS](state, payload) {
    state.authRequestStatus = Status.SUCCESS;
    state.authenticatedUser = payload;
    SessionStorageService.setItem("token", payload.token);
    SessionStorageService.setItem("user", JSON.stringify(payload.user));
  },
  [ACTIONSS.LOGIN_USER_ERROR](state, payload) {
    state.loginError = payload;
    state.authRequestStatus = Status.ERROR;
  },
  [ACTIONSS.LOGIN_USER_LOADING](state) {
    state.loginError = "";
    state.authRequestStatus = Status.LOADING;
  },
  [ACTIONSS.REFRESH_USER_TOKEN_SUCCESS](state, payload) {
    state.authRequestStatus = Status.SUCCESS;

    state.authenticatedUser.token = payload.token;
    state.authenticatedUser.refreshToken = payload.refreshToken;
    state.authenticatedUser.tokenExpiry = payload.tokenExpiry;

    SessionStorageService.removeItem("token");
    SessionStorageService.setItem("token", payload.token);
  },
  [ACTIONSS.REFRESH_USER_TOKEN_ERROR](state, payload) {
    state.loginError = payload;
    state.authRequestStatus = Status.ERROR;
  },
  [ACTIONSS.REFRESH_USER_TOKEN_LOADING](state) {
    state.loginError = "";
    state.authRequestStatus = Status.LOADING;
  },
  [ACTIONS.LOGGING_SUCCESS](state, payload) {
    state.authRequestStatus = Status.SUCCESS;
    state.authenticatedUser = payload;
    SessionStorageService.setItem("token", payload.jwtToken);
  },
  [ACTIONS.LOGGING_ERROR](state, payload) {
    state.loginError = payload;
    state.authRequestStatus = Status.ERROR;
  },
  [ACTIONS.lOGING_LOADING](state) {
    state.loginError = "";
    state.authRequestStatus = Status.LOADING;
  },
  [ACTIONS.RESET_PASSWORD_LOADING](state) {
    state.resetError = "";
    state.authRequestStatus = Status.LOADING;
  },
  [ACTIONS.RESET_PASSWORD_SUCCESS](state) {
    state.resetError = "";
    state.authRequestStatus = Status.SUCCESS;
  },
  [ACTIONS.RESET_PASSWORD_ERROR](state, payload) {
    state.resetError = payload;
    state.authRequestStatus = Status.ERROR;
  },
  [ACTIONS.CHANGE_PASSWORD_LOADING](state) {
    state.changeError = "";
    state.authRequestStatus = Status.LOADING;
  },
  [ACTIONS.CHANGE_PASSWORD_SUCCESS](state) {
    state.changeError = "";
    state.authRequestStatus = Status.SUCCESS;
  },
  [ACTIONS.CHANGE_PASSWORD_ERROR](state, payload) {
    state.changeError = payload;
    state.authRequestStatus = Status.ERRORs;
  },
  [ACTIONS.REMOVE_AUTH](state) {
    state.authenticatedUser = reactive({});
    state.authRequestStatus = ref("");
    SessionStorageService.removeItem("token");
    SessionStorageService.removeItem("user");
    SessionStorageService.clear();
  },
  clearErrors() {
    state.loginError = "";
    state.resetError = "";
    state.changeError = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
